<template>
    <div>
        <div>
            <div class="row mb-3">
                <div class="col-md-2">
                    <img src="http://via.placeholder.com/300" class="userImage"/>
                </div>

                <div class="col-md-10">
                    <div class="row mb-3">
                        <div class="col-md-2" style="text-align: start;">
                            <label for="" >Consultar Solicitante</label>
                            <div class="input-group">
                                
                                <input type="search" class="form-control rounded" placeholder="Buscar" aria-label="Search" aria-describedby="search-addon" />
                                <button type="button" class="btn btn-primary">Buscar</button>
                            </div>
                        </div>

                        <div class="col-md-6 offset-md-4" style="display: flex; align-items: flex-end; ">
                            <div style="width:100%;background-color: #C3DFF2;text-align: center;padding: 10px;">
                                <label><b>BENEFICIARIO NO VINCULADO</b></label>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div class="row mb-3">
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Nombres</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Apellidos</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Código</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Estado</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Cédula de Identidad / Pasaporte</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Género</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Fecha de Nacimiento</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Estado Civil</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Teléfono Casa</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Teléfono Celular</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Correo Electrónico</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-lg-3 col-md-6 form-group">
                                <label for="">Ocupación / Profesión</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <detail-table />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row mb-3">
                        <div class="col-md-12" style="text-align:start;">
                            <label><b><h5>Archivos Adjuntos:</h5></b></label> 
                             &nbsp; 
                            <button class="btn btn-primary"><i class="fa fa-upload"></i> Cargar</button>
                             &nbsp; 
                            <button class="btn btn-primary"><i class="fa fa-link"></i> Vinculacion</button>
                        </div>

                    </div>
                    <form>
                        <div class="row mb-3">
                            <div class="col-md-6 form-group">
                                <label for="">Decisión</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="">Justificación</label>
                                <input
                                    type="text"
                                    id="business_name"
                                    class="form-control"
                                    :readonly="isReadonly"
                                />
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="form-group">
                                <label for="">Comentario u Observación</label>
                                <textarea class="form-control" :readonly="isReadonly"/>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-md-6" style="text-align:start;">
                    <ul style="margin-left:5%;">
                        <li><a href="#" class="link-info"><i class="fa fa-file"></i> Archivo uno </a></li>
                        <li><a href="#" class="link-info"><i class="fa fa-file"></i> Archivo uno </a></li>
                        <li><a href="#" class="link-info"><i class="fa fa-file"></i> Archivo uno </a></li>
                        <li><a href="#" class="link-info"><i class="fa fa-file"></i> Archivo uno </a></li>
                        <li><a href="#" class="link-info"><i class="fa fa-file"></i> Archivo uno </a></li>
                        <li><a href="#" class="link-info"><i class="fa fa-file"></i> Archivo uno </a></li>
                        <li><a href="#" class="link-info"><i class="fa fa-file"></i> Archivo uno </a></li>
                        <li><a href="#" class="link-info"><i class="fa fa-file"></i> Archivo uno </a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import DetailTable from './Components/DetailTable.vue';
export default {
    components:{DetailTable},
    data(){
      return{
        isReadonly: true,
      }  
    },
    setup() {
        
    },
}
</script>
<style scoped>
form .form-group {
  text-align: start;
}
</style>
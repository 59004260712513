<template>
    <div>
        <data-table v-bind="parametersTable1" @actionTriggered="handleAction" />
    </div>
</template>
<script>

export default {
    computed: {
        parametersTable1() {
            return {
                lang: "es",
                data: [
                    {
                        Id: 1,
                        BankEntity: "Banco de Reservas",
                        Result: "Precalificado(a)",
                        Reazon: "N/A",
                        Justification: "N/A",
                    },
                    {
                        Id: 1,
                        BankEntity: "Asociación La Nacional de Ahorros y Préstamos",
                        Result: "Precalificado(a)",
                        Reazon: "N/A",
                        Justification: "N/A",
                    },
                ],
                columns: [
                    {
                        key: "Id",
                        title: "No.",
                    },
                    {
                        key: "BankEntity",
                        title: "Entidad Bancaria",
                    },
                    {
                        key: "Result",
                        title: "Resultado",
                    },
                    {
                        key: "Reazon",
                        title: "Razón",
                    },
                    {
                        key: "Justification",
                        title: "Justificación",
                    },
                ],
            };
        },
    },
    setup() {

    },
}
</script>
<style>
table tr th,
table tr td {
  text-align: start !important;
}
</style>
